import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import styled from 'styled-components';

export const SliderContainer = styled.div`
	&:hover {
		.PrevNextArrowsContainer {
			opacity: 1 !important;
		}
	}
	.PrevNextArrowsContainer {
		opacity: 0.1;
		background-color: rgba(255, 255, 255, 0.4);
		&:hover {
			background-color: rgba(255, 255, 255, 0.9);
		}
	}
`;

export const PrevNextArrowsContainer = styled.div`
	position: absolute;
	z-index: 1;
	@media (max-width: 767px) {
		top: calc(50% - 15px);
		height: 30px;
		width: 30px;
		padding: 5px;
	}
	top: calc(50% - 25px);
	height: 50px;
	width: 50px;

	transition: 0.2s ease all;
	color: #333;
	border-radius: 50%;
	display: flex;
	align-items: center;
	text-align: center;
	cursor: pointer;
	justify-content: center;
	&.carousel-icon-right {
		right: 18px;
	}
	&.carousel-icon-left {
		left: 18px;
	}
`;

export const NextArrow = props => {
	const { onClick } = props;

	//auto scroll
	//setInterval(() => onClick(), 10000)

	return (
		<PrevNextArrowsContainer
			className="icon-container carousel-icon-left PrevNextArrowsContainer"
			onClick={onClick}
		>
			<ChevronLeft size={26} />
		</PrevNextArrowsContainer>
	);
};

export const PrevArrow = props => {
	const { onClick } = props;
	return (
		<PrevNextArrowsContainer
			className="icon-container carousel-icon-right PrevNextArrowsContainer"
			onClick={onClick}
		>
			<ChevronRight size={26} />
		</PrevNextArrowsContainer>
	);
};
