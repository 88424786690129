import React from 'react';
import { Link } from 'gatsby';
import image from './img/BayAreaCoupon.png';
import imagetwo from './img/bayareasidingwindows.webp';

// Helper function to calculate the last day of the current month
const getLastDayOfCurrentMonth = () => {
	const now = new Date();
	const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
	return lastDay.toLocaleDateString('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric'
	});
};

const SidingOffPromotion = () => (
	<>
	 
  
	  <div
		className="rounded-lg mb-2 shadow bg-white"
		style={{
		  background: "linear-gradient(145deg, #17a34a 50%, #F4F9FE 50%)",
		}}
	  >
		<div className="px-8 pt-6">
		  <h4 className="text-white font-bold text-3xl md:text-4xl">$1,500 OFF</h4>
		  <h3 className="text-white font-bold text-lg md:text-xl">
			Whole House Siding Replacement
		  </h3>
		  <Link
			to={"/offers/siding-replacement-special-offer/"}
			className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700 focus:outline-none px-5 py-3 rounded-full bg-white shadow font-display"
		  >
			Claim Offer
		  </Link>
		</div>
  
		<div className="px-8 py-3">
		  <img src={image} alt="Bay Area's Best Siding & Windows" />
		  <p className="text-sm text-center text-gray-500 py-2 italic">
			*Offer expires {getLastDayOfCurrentMonth()}
		  </p>
		</div>
	  </div>

	  <div
		className="rounded-lg mb-2 shadow bg-white"
		style={{
		  background: "linear-gradient(145deg, rgb(249 250 251) 60%, rgb(255 255 255) 50%)",
		}}
	  >
		<div className="px-8 pt-6">
		  <h4 className="text-black font-bold text-3xl md:text-4xl">Try Our 3D Home Design Visualizer!
		  </h4>
		  <h3 className="mt-4 mb-3 text-black text-md md:text-md">
		  Excited to explore new possibilities for your home? Snap a photo to kickstart your journey with our online design tool or get inspired by our sample homes.

		  </h3>
		  <Link
			to={"https://rwpro.renoworks.com/pages/project/automasker?contractor=gvdrenovation"}
			rel="nofollow"
			target="_blank"

			className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700 focus:outline-none px-5 py-3 rounded-full bg-white shadow font-display"
		  >
<span class="flex items-center">Start Designing <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-right h-3 w-3 sm:w-4 sm:h-4 ml-1"><path d="M5 12h14"></path><path d="m12 5 7 7-7 7"></path></svg></span>		  </Link>
		</div>
  
		<div className="px-8 py-3">
		  <img src={imagetwo} alt="Bay Area Exterior Home Design Visualizer" />
		 
		</div>
	  </div>
	</>
  );
  

export default SidingOffPromotion;
