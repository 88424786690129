import React, {  useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';

import { useWindowSize } from '@react-hook/window-size';

import SEO from '../components/seo';
import Layout from '../components/layout';
import SidingOffPromotion from '../components/coupons/SidingOffPromotion';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
	FacebookShareButton,
	TwitterShareButton,
	EmailShareButton,
	LinkedinShareButton
} from 'react-share';
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather';

import ContactForm from '../components/estimate-form';

import Breadcrumbs from '../components/breadcrumbs';

import '../styles/projects-gallery.scss';

import Slider from 'react-slick';

import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import 'yet-another-react-lightbox/styles.css';
import {
	SliderContainer,
	PrevArrow,
	NextArrow
} from '../components/slider-components';

const ContactCard = styled.div`
	top: 113px !important;
	background: rgb(249 249 249);
`;

const BlogPostLayout = styled.div`
	background: #fff;
	.articleBody {
		max-width: 900px;
		margin: 0 auto;
		bottom: 1px solid #717bba;
	}
`;
const Header = styled.div`
	text-align: left;
	margin: 0 auto;
	margin-bottom: 1em;
	border-bottom: 1px solid #eaeaea;
	h1 {
		font-size: calc(1.15vw + 1.25em);
		line-height: 1.15;
		font-weight: 800;
		color: #000;
	}
	border-bottom: 1px solid #f1f1f1;
	background: #f7f7f7;
`;

const BlogPostImage = styled.div`
	img {
		max-width: 900px;
		margn: 0 auto;
		text-align: center;
		border-radius: 0;
		margin-bottom: 1em;
		box-shadow: none !important;
	}
`;
const ShareThisPost = styled.div`
	p {
		font-weight: bold;
		margin: 0;
	}
	display: flex;
	align-items: center;
	.SocialMediaShareButton {
		display: flex;
		padding: 0 9px;
	}
	svg {
		stroke: none;
		fill: #888;
		cursor: pointer;
	}
	.stroke-white {
		stroke: white;
	}
`;

const CustomLightbox = styled(Lightbox)`
	.react-modal {
		padding: 0 !important;
	}
	.yarl__slide {
		padding: 0 !important;
	}
	& img {
		margin: 0 !important;
	}
`;
const ResponsiveImage = styled(GatsbyImage)`
	height: 213px;

	@media (max-width: 768px) {
		height: 110px;
	}
`;

const ImageGalleryImage = ({
	fluid,
	fullScreen,
	i,
	setMaxHeight,
	maxHeight,
	windowHeight,
	windowWidth,
	isFullScreen,
	pagePath,
	paddingTop,
	activeImageState,
	...otherprops
}) => {
	const gatsbyImgRef = useRef();

	return (
		<div
			className="aspect-ratio-box projects-project"
			ref={gatsbyImgRef}
			style={{
				paddingTop,
				display:
					i >= activeImageState - 1 && i <= activeImageState + 1
						? 'block'
						: 'none'
			}} //lock aspect ratio with padding; no jumping because content size is provided and aspect ratio is computed for each page; lazy load images 3 images inView and images before and after
		>
			<GatsbyImage
				className="image-gallery-image overflow-hidden mb-0 aspect-ratio-box-inside"
				image={getImage(otherprops)}
				// onLoad={e =>
				//   updateActiveImageLoadingState(
				//     activeImageLoadingState.map(([index, state]) => {
				//       if (index == i) return [i, true]
				//       return [index, state]
				//     })
				//   )
				// }
				alt=""
			/>
		</div>
	);
};

const settings = {
	accessibility: true,
	dots: true,
	infinite: true,
	// autoplay: true,
	autoplaySpeed: 7000,
	pauseOnHover: false,
	// speed: 300,
	slidesToShow: 1,
	slidesToScroll: 1,
	swipeToSlide: true,
	fade: false,
	cssEase: 'linear',
	className: 'disable-interaction-on-non-active relative',
	nextArrow: <PrevArrow />,
	prevArrow: <NextArrow />
};

const SliderCarousel = props => {
	const { images, activeImageState } = props;
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isFullScreen, setFullScreen] = useState(false);
	const [maxHeight, setMaxHeight] = useState(0);
	const [windowWidth, windowHeight] = useWindowSize({ wait: 100 });

	const paddingTop =
		(images.length > 0 && images[0].gatsbyImageData
			? (images[0].gatsbyImageData.height /
					images[0].gatsbyImageData.width) *
			  100
			: 50) + '%';

	return (
		<SliderContainer className="project-gallery-container">
			{/* {showImageLoader && (
    <Loading className="w-14 h-14 absolute-center z-10" />
  )} */}
			<Slider
				{...settings}
				beforeChange={(oldIndex, newIndex) =>
					setSelectedIndex(newIndex)
				}
			>
				{images.map((props, i) => (
					<ImageGalleryImage
						key={i}
						maxHeight={maxHeight}
						windowHeight={windowHeight}
						windowWidth={windowWidth}
						pagePath={props.path}
						isFullScreen={isFullScreen}
						setMaxHeight={setMaxHeight}
						paddingTop={paddingTop}
						activeImageState={selectedIndex}
						{...props}
					/>
				))}
			</Slider>
		</SliderContainer>
	);
};

const ProjectPage = props => {
	const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
	const activeImageState = useState(
		props.data.contentfulProjects &&
			props.data.contentfulProjects.photoGallery &&
			props.data.contentfulProjects.photoGallery.photos &&
			props.data.contentfulProjects.photoGallery.photos.map((_, i) => [
				i,
				false
			])
	);
	const [activeImageLoadingState, updateActiveImageLoadingState] =
		activeImageState;
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isFullScreen, setFullScreen] = useState(false);
	const [maxHeight, setMaxHeight] = useState(0);
	const [windowWidth, windowHeight] = useWindowSize({ wait: 100 });

	const toggleLightbox = selectedIndex => {
		setLightboxIsOpen(!lightboxIsOpen);
		setSelectedIndex(selectedIndex);
	};

	const { isLoading } = props;

	const post = props.data.contentfulProjects;
	const siteurl = props.data.site.siteMetadata.siteUrl;
	const relatedPosts = [];

	const { moreInCategory } = props.data;

	const images =
		post &&
		post.photoGallery &&
		post.photoGallery.photos.map(
			({ id, smallerFluid, file, fullscreen, title, ...rest }, i) => {
				return {
					i,
					id: i,
					caption: 'Bay Area Siding Company',
					src: `https:${file.url}`,
					w: 1200,
					h: 900,
					alt: title,
					thumbnail: smallerFluid.src,
					source: {
						original: `https:${file.url}`,
						regular: `https:${file.url}`,
						fullscreen: `https:${file.url}`
					},
					file: {
						url: `https:${file.url}`
					},
					smallerFluid,
					...rest
				};
			}
		);

	// const showImageLoader = !activeImageLoadingState[selectedIndex][1]

	return (
		<Layout pageProps={props}>
			<div className="bg-white border-b border-gray-100">
				<nav
					className="flex container mx-auto px-4 py-3 max-w-7xl"
					aria-label="Breadcrumb"
				>
					<Breadcrumbs
						links={[
							{ to: '/', label: 'Home' },
							{ to: '/projects/', label: 'Projects' },
							{
								to: props.location.pathname,
								label: `${post.title} in ${post.city}, ${post.zipCode}`
							}
						]}
					/>
				</nav>
			</div>
			<SEO
				title={
					post.metaTitle
						? post.metaTitle
						: post.title +
						  ' in ' +
						  post.city +
						  ', CA ' +
						  post.zipCode +
						  ' | Bay Area Siding Company'
				}
				description={
					post.metaDescription
						? post.metaDescription
						: 'See Photos of This ' +
						  post.title +
						  ' In ' +
						  post.city +
						  ' CA, ' +
						  post.zipCode +
						  ' Completed By Bay Area Siding Company.'
				}
				img={post.thumbnail.gatsbyImageData.images.fallback.src}
				twitterCardContent={'summary_large_image'}
			/>
			<BlogPostLayout className="relative">
				<div>
					<Header>
						<div className="lg:flex container lg:row mx-auto max-w-7xl">
							<div
								className={`lg:container py-12 text-center blogimgmw w-full lg:w-2/3 relative px-4 lg:px-0 ${
									isFullScreen ? 'z-20000' : 'z-10'
								}`}
								style={{
									maxWidth: '1200px !important'
								}}
							>
								<h1
									itemProp="name headline"
									className="text-left -mt-3 mb-4 lg:ml-4"
								>
									{post.title} in {post.city}, {post.zipCode}
								</h1>
								{post.description && (
									<p
										className="text-left text-base text-gray-700 mb-6 leading-7 lg:ml-4"
										dangerouslySetInnerHTML={{
											__html: post.description.description
										}}
									/>
								)}

								<ShareThisPost className="pr-3 justify-content-start py-1 flex-1 mb-3 lg:ml-4">
									<p className="hidden md:block text-base tracking-widest uppercase">
										Share —{' '}
									</p>
									<FacebookShareButton
										url={siteurl + '/projects/' + post.slug}
										quote={post.title}
										hashtag={'#bayareasidingcompany'}
										className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
									>
										<Facebook size={20} />
									</FacebookShareButton>

									<TwitterShareButton
										url={siteurl + '/projects/' + post.slug}
										title={post.title}
										hashtags={post.tags}
										className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
									>
										<Twitter size={20} />
									</TwitterShareButton>
									<LinkedinShareButton
										url={siteurl + '/projects/' + post.slug}
										title={post.title}
										quote={post.title}
										hashtags={post.tags}
										className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
									>
										<Linkedin size={20} />
									</LinkedinShareButton>
									<EmailShareButton
										subject={post.title}
										url={siteurl + '/projects/' + post.slug}
										openWindow={true}
										body={'Check out this article: '}
										className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
									>
										<Mail
											size={20}
											className="stroke-white"
										/>
									</EmailShareButton>
								</ShareThisPost>
								<div className="grid grid-cols-3 gap-4 lg:ml-4">
									{images.map((photo, index) => (
										<div
											key={index}
											onClick={() =>
												toggleLightbox(index)
											}
											className="z-20"
										>
											<ResponsiveImage
												image={getImage(
													photo.gatsbyImageData
												)}
												alt={post.title}
												className="cursor-pointer"
												id={`id-${index}`}
											/>
										</div>
									))}
									{lightboxIsOpen && (
										<CustomLightbox
											slides={images}
											open={lightboxIsOpen}
											index={selectedIndex}
											close={() =>
												setLightboxIsOpen(false)
											}
											plugins={[Fullscreen]}
										/>
									)}
								</div>
							</div>
							<div className="w-full lg:w-1/3"></div>
						</div>
					</Header>
					<div className="lg:flex lg:container lg:row mx-auto">
						<div className="relative pt-2 w-full lg:w-2/3 z-10 lg:mt-16">
							{(post.review || post.beforeAndAfter) && (
								<div className="container mt-12">
									<div className="row lg:p-6"></div>
								</div>
							)}
						</div>
						<div className="w-full lg:w-1/3"></div>
						<div className="lg:container block lg:flex lg:row w-full h-full top-0 justify-end relative lg:absolute">
							<div className="w-full lg:w-2/3 invisible" />
							<div className="container w-full lg:w-1/3 lg:py-12 relative lg:justify-self-end pb-6 lg:p-6 lg:pt-6 mx-auto lg:mx-0">
								<ContactCard className="p-6 shadow-lg sticky top-0  border-t-4 border-brand-500 text-gray-700 rounded-2xl 2xl:max-w-[340px]">
									<span className="block text-center text-xl font-semibold mb-1 text-black">
										Need Help With Your Project?
									</span>
									<h3 className="text-center">
										Reach Out To Us Today!
									</h3>
									<div className="sidebarform">
										<ContactForm
											showLabels
											showPlaceHolders
											pageLocation={
												props.location.href
													? props.location.href
													: 'Not provided'
											}
											projects
										/>
									</div>
								</ContactCard>
							</div>
						</div>
					</div>
				</div>
			</BlogPostLayout>
			{moreInCategory &&
			moreInCategory.edges &&
			moreInCategory.edges.length > 0 ? (
				<div className="container mx-auto p-4 py-8 max-w-7xl">
					<h3
						className="text-xl font-semibold pb-4
"
					>
						Explore More of Our Stunning {post.categories[0].name}{' '}
						Projects!
					</h3>
					<div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
						{moreInCategory &&
							moreInCategory.edges &&
							moreInCategory.edges.map((item, i) => (
								<Link
									key={i}
									to={`/projects/${item.node.slug}/`}
									className="bg-white shadow hover:shadow-lg rounded-t-2xl rounded-b-2xl"
								>
									<GatsbyImage
										image={getImage(item.node.heroImage)}
										style={{ height: '150px' }}
										alt={item.node.title}
										className="rounded-t-2xl"
									/>
									<div className="p-3 py-4 pt-3">
										<span className="text-gray-700 text-base">
											{item.node.publishDate}
										</span>
										<span className=" font-semibold leading-tight text-gray-900 block">
											{item.node.title}
										</span>
										<div className="flex items-center text-gray-800 text-xs mt-1">
											<div className="">
												<img
													src="/assets/location-pin.svg"
													className="w-2 mb-0"
												/>
											</div>
											<div className="ml-2 text-xs">
												{item.node.city}, CA{' '}
												{item.node.zipCode}
											</div>
										</div>
									</div>
								</Link>
							))}
					</div>
<div className="  max-w-7xl   flex flex-wrap mx-auto  ">
					<div className="w-full md:w-2/3 p-0 md:pr-16 relative articleBody  z-1 prose">
						<p
							dangerouslySetInnerHTML={{
								__html: post.additionalContent.additionalContent
							}}
						/>
					</div>
					<div class="mt-8 w-full md:w-1/3 p-0 md:pl-6"><SidingOffPromotion /></div>
				</div></div>
			) : null}
		</Layout>
	);
};

export const pageQuery = graphql`
	query ProjectPage($slug: String!, $categoryId: [String]) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		contentfulProjects(slug: { eq: $slug }) {
			id
			title
			slug
			categories: category {
				id
				name
				slug
			}
			date
			city
			zipCode
			thumbnail {
				gatsbyImageData(width: 1500, quality: 70)
			}
			heroImage: thumbnail {
				gatsbyImageData(width: 1500, quality: 70)
			}
			photoGallery {
				id
				photos {
					id
					smallerFluid: gatsbyImageData(
						quality: 70
						placeholder: NONE
						height: 1000
					)
					fullscreen: gatsbyImageData(quality: 80, width: 1874)
					file {
						url
					}
					gatsbyImageData(quality: 70, width: 1000, placeholder: NONE)
				}
			}
			description {
				id
				description
			}
			additionalContent {
				id
				additionalContent
			}
			videoTestimonialRef {
				id
				thumbnail {
					gatsbyImageData(width: 1000, quality: 70, placeholder: NONE)
				}
				video {
					file {
						url
						contentType
					}
				}
			}
			review {
				id
				source
				date
				reviewTitle
				reviewer
				source
				date
				category {
					id
					name
					slug
				}
				reviewBody {
					id
					reviewBody
				}
			}
		}
		moreInCategory: allContentfulProjects(
			filter: {
				category: { elemMatch: { id: { in: $categoryId } } }
				slug: { ne: $slug }
			}
			limit: 8
			sort: { order: DESC, fields: date }
		) {
			edges {
				node {
					id
					title
					slug
					date
					city
					zipCode
					heroImage: thumbnail {
						gatsbyImageData(
							width: 1200
							quality: 70
							placeholder: NONE
						)
					}
				}
			}
		}
	}
`;

export default ProjectPage;
